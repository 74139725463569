<template>
	<div class="grid">
		<div class="col-12">
			<div class="card">
				<Toast/>
				<ConfirmDialog />

				<DataTable ref="dt" :value="state.sites" selectionMode="single" v-model:selection="state.selectedSite"
						dataKey="site_id" :paginator="true" :rows="10" :filters="state.filters" :globalFilterFields="['site_name']"
						sortField="site_name" :sortOrder="1"
						paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25]"
						currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products" responsiveLayout="scroll">
					<template #header>
						<div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
							<h5 class="m-0">Parking Spaces</h5>
							<Button :label="state.polling ? 'Polling: ON' : 'Polling: OFF'" @click="state.polling = !state.polling" :class="state.polling ? 'p-button-success' : 'p-button-secondary'" />
							<span class="block mt-2 md:mt-0 p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="state.filters['site_name'].value" placeholder="Search..." />
                            </span>
						</div>
					</template>

					<Column selectionMode="single" headerStyle="width: 3rem"></Column>
					<Column field="site_name" header="Space" :sortable="true" headerStyle="width:10%; min-width:5rem;">
						<template #body="slotProps">
							<span class="p-column-title">Space</span>
							{{slotProps.data.site_name}}
						</template>
					</Column>
					<Column field="occupancy" header="Occupancy" :sortable="true" headerStyle="width:14%; min-width:10rem;">
						<template #body="slotProps">
							<span class="p-column-title">Occupancy</span>
							<span :class="'occupancy-badge status-' + (slotProps.data.occupancy ? slotProps.data.occupancy.toLowerCase() : '')">{{slotProps.data.occupancy}}</span>
						</template>
					</Column>
					<Column field="fybrtag" header="Tag" :sortable="true" headerStyle="width:14%; min-width:10rem;">
						<template #body="slotProps">
							<span class="p-column-title">Tag</span>
							<span :class="'occupancy-badge'">{{slotProps.data.fybrtag}}</span>
						</template>
					</Column>
					<Column field="occupancy_change_timestamp" header="Since" :sortable="true" headerStyle="width:14%; min-width:12rem;">
						<template #body="slotProps">
							<span class="p-column-title">Occupancy Since</span>
							{{utils.formatDateTime(slotProps.data.occupancy_change_timestamp)}}
						</template>
					</Column>
					<Column field="payment_status" header="Payment" :sortable="true" headerStyle="width:14%; min-width:8rem;">
						<template #body="slotProps">
							<span class="p-column-title">Payment</span>
							<span :class="'payment-badge status-' + (slotProps.data.payment_status ? slotProps.data.payment_status.toLowerCase() : '')">{{slotProps.data.payment_status}}</span>
						</template>
					</Column>
					<Column field="paid_until" header="Until" :sortable="true" headerStyle="width:14%; min-width:12rem;">
						<template #body="slotProps">
							<span class="p-column-title">Until</span>
							{{utils.formatDateTime(slotProps.data.paid_until)}}
						</template>
					</Column>
					<Column headerStyle="min-width:30rem;">
						<template #body="slotProps">
							<Button label="Mark Paid" class="p-button-rounded p-button-success mr-2" icon="pi pi-dollar" iconPos="left" @click="showPaymentDialog(slotProps.data)" />
							<Button label="Payments" class="p-button-rounded p-button-info mt-2" icon="pi pi-list" iconPos="left" @click="showPaymentHistoryDialog(slotProps.data)" />
						</template>
					</Column>
				</DataTable>

				<Dialog v-model:visible="state.showPaymentDialog" :style="{width: '500px'}" :header="state.selectedSite.site_name + ' - New Payment Record'" :modal="true" class="p-fluid" @hide="cancelPayment">
					<div class="formgrid grid">
						<div class="field col">
							<div class="field">
								<label for="paymentType" :class="{'p-error':state.invalidPaymentType, 'font-bold': true, 'mb-2': true}">Payment Type</label>
								<Dropdown v-model="state.newReservation.payment_type" :options="state.paymentOptions" optionLabel="name" optionValue="name" :editable="true" placeholder="Enter or Select" :class="{'p-invalid':state.invalidPaymentType}" />
							</div>
						</div>
					</div>

					<div class="formgrid grid">
						<div class="field col">
							<label for="fromTs" class="font-bold">From</label>
							<Calendar inputId="fromTs" v-model="state.newReservation.from_ts" :showTime="true" hourFormat="12" :showIcon="true" />
						</div>
						<div class="field col">
							<label for="toTs" class="font-bold">To</label>
							<Calendar inputId="toTs" v-model="state.newReservation.to_ts" :showTime="true" hourFormat="12" :showIcon="true" />
						</div>
					</div>

					<template #footer>
						<Button label="Cancel" icon="pi pi-times" class="p-button-outlined p-button-rounded p-button-danger" @click="cancelPayment"/>
						<Button label="Save" icon="pi pi-check" class="p-button-rounded p-button-success" @click="savePayment" />
					</template>
				</Dialog>

				<Dialog v-model:visible="state.showPaymentHistoryDialog" :style="{width: '800px'}" :header="state.selectedSite.site_name + ' - Payment History'" :modal="true" :dismissableMask="true">
					<div class="flex align-items-center justify-content-center">
						<DataTable :value="state.reservations" :scrollable="true" scrollHeight="500px" style="width:100%;" sortField="to_ts" :sortOrder="-1">
							<Column field="reservation_id" header="Id" ></Column>
							<Column field="payment_type" header="Payment" ></Column>
							<Column header="Until" style="min-width:12rem;">
								<template #body="slotProps">
									{{utils.formatDateTime(slotProps.data.to_ts)}}
								</template>
							</Column>
							<Column header="Total">
								<template #body="slotProps">
									<span class="p-column-title">Until</span>
									{{utils.formatCurrency(slotProps.data.cost)}}
								</template>
							</Column>
							<Column>
								<template #body="slotProps">
									<span v-if="utils.timeNotPassed(slotProps.data.to_ts) && slotProps.data.is_active" :class="'payment-badge status-paid'">ACTIVE</span>
								</template>
							</Column>
							<Column>
								<template #body="slotProps">
									<Button label="Delete" class="p-button-rounded p-button-danger mt-2" icon="pi pi-trash" iconPos="left" @click="deletePayment(slotProps.data)" />
								</template>
							</Column>
						</DataTable>
					</div>
				</Dialog>

			</div>
		</div>
	</div>

</template>

<script>
import { onBeforeUnmount, reactive, watch, watchEffect } from 'vue';
import { FilterMatchMode } from 'primevue/api';
import { useConfirm } from 'primevue/useconfirm';
import { useToast } from 'primevue/usetoast';
import { useApi } from '@/composables/useApi';
// eslint-disable-next-line no-unused-vars
import { reservationCreate, reservationDelete, reservationsListBySite, sitesList } from '@/api/interface';
import { DateTime } from 'luxon';
import utils from '@/utils';

export default {
	name: 'Spaces',

	setup() {
		const state = reactive({
			sites: null,
			filters: {
				'site_name': {value: null, matchMode: FilterMatchMode.CONTAINS}
			},
			reservations: null,
			selectedSite: { site_name: '' },
			showPaymentHistoryDialog: false,
			showPaymentDialog: false,
			newReservation: { space_name: '' },
			paymentOptions: [
				{name: 'Point of Sale', iconClasses: {pi:true, 'pi-credit-card':true, 'mr-3':true}},
				{name: 'Annual Pass', iconClasses: 'pi pi-ticket mr-3'}
			],
			invalidPaymentType: false,
			polling: false
		});
		const confirm = useConfirm();
		const toast = useToast();
		const { api_error, callApi } = useApi();
		const jwt = localStorage.getItem('jwt');
		let refreshInterval = null;
		let loading = false;

		const cancelPayment = () => {
			state.selectedSite = { site_name: '' };
			state.showPaymentDialog = false;
			state.newReservation = null;
			state.invalidCost = false;
			state.invalidPaymentType = false;
		}

		const deletePayment = (reservation) => {
			confirm.require({
				message: 'Are you sure you want to delete this payment?',
				icon: 'pi pi-question-circle',
				acceptClass: 'p-button-danger',
				accept: () => {
					callApi(reservationDelete(reservation.reservation_id, jwt), () => {
						state.sites = null;
						state.reservations = null;
						toast.add({severity: 'success', summary: 'Operation Complete', detail: 'Payment deleted successfully.', life: 3000});
					});
				}
			});
		}

		const savePayment  = () => {
			let isFormValid = true;

			if (!state.newReservation.payment_type) {
				isFormValid = false;
				state.invalidPaymentType = true;
				toast.add({severity: 'error', summary: 'Validation Error', detail: 'Payment Type required.', life:5000});
			} else {
				state.invalidPaymentType = false;
			}

			if (isFormValid) {
				callApi(reservationCreate(state.newReservation, jwt), () => {
					state.showPaymentDialog = false;
					state.sites = null;
					toast.add({severity: 'success', summary: 'Operation Complete', detail: 'Payment saved successfully.', life: 3000});
				});
			}
		}

		const showPaymentDialog = site => {
			state.selectedSite = site;
			state.showPaymentDialog = true;
			state.newReservation = {
				site_id: site.site_id,
				space_name: site.site_name,
				payment_type: 'Point of Sale',
				from_ts: new Date(),
				to_ts: DateTime.now().plus({ hours: 13 }).toJSDate(),
				cost: 2.0
			};
		}

		const showPaymentHistoryDialog = site => {
			state.selectedSite = site;
			callApi(reservationsListBySite(site.site_id, jwt), responseData => {
				state.showPaymentHistoryDialog = true;
				state.reservations = responseData;
			});
		}

		const refresh = () => {
			if (!loading) {
				loading = true;
				callApi(sitesList(jwt), responseData => {
					state.sites = responseData;
					loading = false;
				});
			} else {
				console.log("still loading...");
			}
		}

		watch(api_error, api_error => {
			if(api_error) {
				toast.add({severity: 'error', summary: 'API Error', detail:api_error, life: 5000});
			}
		});

		watchEffect(() => {
			if(state.sites === null) {
				callApi(sitesList(jwt), responseData => {
					state.sites = responseData;
				});
			}
		});

		watchEffect(() => {
			if (state.selectedSite.site_id && state.reservations === null) {
				callApi(reservationsListBySite(state.selectedSite.site_id, jwt), responseData => {
					state.reservations = responseData;
				});
			}
		})

		watchEffect(() => {
			if(state.polling) {
				refreshInterval = setInterval(function () {
					refresh();
				}.bind(this), 3000);
			} else {
				clearInterval(refreshInterval);
			}
		});

		onBeforeUnmount(() => {
			clearInterval(refreshInterval);
		});

		return { state, utils, cancelPayment, deletePayment, savePayment, showPaymentDialog, showPaymentHistoryDialog }
	}
}
</script>

<style scoped lang="scss">
@import '../assets/styles/badges.scss';
</style>
